import React, { useState } from 'react';

import './team-display.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTeamImages } from './team-display-hook';

const team = [
  {
    name: 'kevin walsh',
    path: 'kevin.png',
    link: 'https://ie.linkedin.com/in/kevin-walsh-913a7557'
  },
  {
    name: 'elaine moore',
    path: 'elaine.png',
    link: 'https://ie.linkedin.com/in/elaine-moore-bba992143'
  },
  {
    name: `thomas o'brien`,
    path: 'tom.png',
    link: 'https://www.linkedin.com/in/thomas-o-brien-52189a61/'
  },
  {
    name: 'craig neale',
    path: 'craig-headsot-aphex-bluecorner.png',
    link: 'https://www.linkedin.com/in/craig-neale-5aa1b3137/'
  },
  {
    name: 'niall cusack',
    path: 'niall.png',
    link: 'https://ie.linkedin.com/in/niallcusack'
  },
  {
    name: 'david power',
    path: 'dave-headshot-aphex-bluecorner.png',
    link: 'https://www.linkedin.com/in/david-power-30b23ab8/'
  },
  {
    name: 'phil corley',
    path: 'phil-headshot-aphex-bluecorner.png',
    link: 'https://www.linkedin.com/in/philip-cluxton-corley-218781186/'
  },
  {
    name: 'rachael kennedy',
    path: 'rachel-headshot-aphex-bluecorner.png',
    link: 'https://www.linkedin.com/in/rachael-kennedy-171433158/'
  },
  {
    name: 'helen halpin',
    path: 'helen-headshot-aphex-bluecorner.png',
    link: 'https://www.linkedin.com/in/helen-halpin-9a516a69/'
  },
  {
    name: 'rachel kehoe',
    path: 'rachel-kehoe-headshot-aphex-bluecorner.png',
    link: 'https://www.linkedin.com/in/rachel-dignam-kehoe-82292b172/'
  }
];

/**
 * Displays a team member photo with a link to linkedIn
 * @param {{ name, path, link, biography, data }} member
 */
const TeamMember = member => {
  const [display, setDisplay] = useState(false);

  const toggleDisplay = () => {
    setDisplay(state => !state);
  };

  return (
    <figure
      key={member.name}
      onMouseEnter={toggleDisplay}
      onMouseLeave={toggleDisplay}
    >
      <div>
        <img src={member.data.src} alt={member.name} />
        {display && member.biography && (
          <div className="biography">
            <p>{member.biography}</p>
          </div>
        )}
      </div>
      <figcaption>
        <a href={member.link} target="_blank" rel="noreferrer">
          <span>{member.name}</span>
          <FontAwesomeIcon icon="arrow-circle-right" />
        </a>
      </figcaption>
    </figure>
  );
};

/**
 * Displays all team member photos, on the 'Story' page.
 */
export const TeamDisplay = () => {
  const images = useTeamImages();
  const teamData = team.map(member => {
    let data = images.find(image => image.relativePath.includes(member.path));
    return { ...member, data: data.childImageSharp.fixed };
  });
  return (
    <div id="team-display">
      <div>
        <h1>About Us</h1>
        <p>
          Located in the sunny south east of Ireland, in County Waterford, the
          Aphex Group was established in 2017, and has gone from strength to
          strength, dedicated to delivering a ‘best in class’ service in
          recruitment, validation, and software for managing waste disposal.
        </p>
        <p>
          Founded by Kevin Walsh, the Aphex offering originally began as a Life
          Sciences consultancy, with a humble desk in Fumbally Exchange,
          focusing exclusively on validation and recruitment services.
          Chemishield was the next offering to arrive, which was born out of an
          incident nearly a decade ago.
        </p>
        <p>
          We have crafted and refined the service offering of the Aphex Group
          with a full re-brand in the summer of 2020. Our headquarters are now
          in ArcLabs Research &amp; Innovation Centre, providing ample space for
          our growing team and expansion plans.
        </p>
      </div>
      <div>{teamData.map(member => TeamMember(member))}</div>
    </div>
  );
};
