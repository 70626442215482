import React from 'react';
import { NavBar } from '../components/navbar';

import '../components/fa-icons';
import '../scss/global.scss';
import { Footer } from '../components/footer';
import { TeamDisplay } from '../components/team-display';
import { CookieConsent } from '../components/cookie-consent';
import { Head } from '../components/head';

export default function () {
  return (
    <>
      <Head
        title="The Aphex Group - About Us"
        description="Learn more about The Aphex Group and our service offerings including recruitment, validation, software development and web design."
      />
      <CookieConsent />
      <NavBar />
      <TeamDisplay />
      <Footer />
    </>
  );
}
